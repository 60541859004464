<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="lg" :title="`${this.$route.params.absenceId ? 'View/Update' : 'Create'} Absence`" @hidden="closeModal">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group label="User" :invalid-feedback="getValidationMessage('user_id')" :state="!validationErrors.user_id">
                <user-search v-model="editedAbsence.user_id" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="First Date" :invalid-feedback="getValidationMessage('start_date')" :state="!validationErrors.start_date">
                <b-form-datepicker v-model="editedAbsence.start_date"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Last Date" :invalid-feedback="getValidationMessage('end_date')" :state="!validationErrors.end_date">
                <b-form-datepicker v-model="editedAbsence.end_date"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Type" :invalid-feedback="getValidationMessage('type')" :state="!validationErrors.type">
                <b-form-select :options="[
                  { text: 'Holiday', value: 'holiday' },
                  { text: 'Sickness', value: 'sickness' }
                ]" v-model="editedAbsence.type" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Working Days" :invalid-feedback="getValidationMessage('working_days')" :state="!validationErrors.working_days">
                <b-form-input type="number" v-model="editedAbsence.working_days" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Notes" :invalid-feedback="getValidationMessage('notes')" :state="!validationErrors.notes">
                <b-form-textarea v-model="editedAbsence.notes"></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="saveAbsence" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">{{ $route.params.absenceId ? 'Update' : 'Create' }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UserSearch from '../../components/UserSearch.vue'
import handleErrors from '../../mixins/handleErrors';

export default {
  components: { UserSearch },
  mixins: [handleErrors],
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      editedAbsence: {
        user_id: 0,
        end_date: null,
        start_date: null,
        working_days: 1,
        type: '',
        note: '',
      },
      loading: false,
      saving: false,
      error: '',
    };
  },
  props: {
    absence: Object,
    id: String,
  },
  async mounted() {
    if (this.$route.params.absenceId && this.absence) {
      this.loading = true;

      try {
        const absence = await this.fetchSingle(this.$route.params.absenceId);
        this.editedAbsence = {
          ...absence,
        };
        this.loading = false;
      } catch (err) {
        this.error = "Can not load absence. Please try again later.";
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('absences', ['fetch', 'fetchSingle', 'updateOrCreate']),
    closeModal() {
      this.absense = {};
      this.editedAbsence = {};
      this.validationErrors = {};
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
    },
    saveAbsence() {
      this.saving = true;
      this.updateOrCreate({
        absentable_id: this.editedAbsence.user_id,
        ...this.editedAbsence
      }).then(() => {
        this.saving = false;
        this.genericError = undefined;
        this.closeModal();
        return this.fetch();
      }).catch(err => {
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    }
  },
  watch: {
    absence(value) {
      this.editedAbsence = {
        ...value,
      };
    }
  }
}
</script>

<style>

</style>
